import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FaEye, FaEyeSlash, FaStickyNote, FaVrCardboard } from 'react-icons/fa'; 
import style from "./Login.module.css";
import LogoCallidus from '../../img/Logo_Callidus.png';
import api from '../../services/api-login.js';
import Typewriter from "../../components/Typewriter/typewritter.jsx";
import ParticlesBackground from '../../components/ParticlesBackground/ParticlesBackground.js';
import Modal from "../../components/Modal/modal.jsx";
import MapImg from "../../img/sts-map.svg";
import AlamoaMapImg from "../../img/alamoa-map.svg";
import SabooMapImg from "../../img/saboo-map.svg";
import ValongoMapImg from "../../img/valongo-map.svg";
import QuintaMapImg from "../../img/5-seccao-map.svg";
import PaquetaMapImg from "../../img/paqueta-map.svg";
import OuteirinhosMapImg from "../../img/outeirinhos-map.svg";
import { useSnackbar } from "notistack";

const Login = () => {
  const { token } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [id, setId] = useState("");
  const [senha, setSenha] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [iframeUrl, setIframeUrl] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [vrModal, setVrModal] = useState(false);
  const [reset, setReset] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [tokenState, setTokenState] = useState(null);

  useEffect(() => {
    if (token) {
      setTokenState(token); // Armazena o token no estado
    }
  }, [token]);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!reset) {
      if (!id || !senha) {
        setError('*Preencha todos os campos*');
        return;
      }
      try {
        const response = await api.post('/api/login', {}, { params: { usuario: id, senha: senha } });
        localStorage.setItem('token', response.data);
        console.log(response.data);
        
        setFadeOut(true);
        setTimeout(() => navigate("/dashboard"), 500); // 500ms for animation
      } catch (err) {
        if (err.response && err.response.status === 401) {
          setError('Usuário ou senha inválida');
        } else {
          setError('Erro ao fazer login. Tente novamente mais tarde.');
        }
      }
    } else if (!email) {
      const email = await api.get('/api/login/forgot/email', { params: { user: id } })
      setResetEmail(email.data.email)
    } else {
      const response = await api.post('/api/login/forgot', {}, { params: { 'user': id, 'email': email } });
      console.log(response.data)
      setLoading(true)
      setTimeout(() => {
        setLoading(false);
        setSuccess(true);
      }, 2000); 
    }
  };

  const handleClick = () => setVrModal(true);

  const handleClose = () => {
    setVrModal(false);
    setIframeUrl("");
  };

  const handleMapClick = (url) => {
    setIframeUrl(url);
    setVrModal(true);
  };

  const handleNull = () => {
    enqueueSnackbar('Região ainda não catalogada.', { variant: "error" });
  };

  const handleForgotPassword = () => {
    setReset(true);
  };

  const handleBackToLogin = () => {
    setReset(false);
    setSuccess(false)
    setEmail('')
    setResetEmail("")
  };

  const handleBack = (url) => {
    setIframeUrl(null);
    if (!iframeUrl) {
      setVrModal(false)
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (id !== senha) {
      enqueueSnackbar('Senhas não coincidem!', {variant: 'error'})
    } 
    else if (senha.length < 8) {
      enqueueSnackbar('A senha deve ter pelo menos 8 dígitos!', { variant: 'error' });
    } 
    else if (senha.length > 20) {
      enqueueSnackbar('A senha deve ter no máximo 20 dígitos!', { variant: 'error' });
    }
    else if (!/[A-Z]/.test(senha)) {
      enqueueSnackbar('A senha deve conter pelo menos uma letra maiúscula!', { variant: 'error' });
    }
    else if (!/[a-z]/.test(senha)) {
      enqueueSnackbar('A senha deve conter pelo menos uma letra minúscula!', { variant: 'error' });
    }
    else if (!/\d/.test(senha)) {
      enqueueSnackbar('A senha deve conter pelo menos um número!', { variant: 'error' });
    }
    else if (!/[!@#$%^&*(),.?":{}|<>]/.test(senha)) {
      enqueueSnackbar('A senha deve conter pelo menos um caractere especial!', { variant: 'error' });
    } else {
      try {
        const response = await api.post('/api/login/forgot/reset', {}, {
          params: { token: tokenState, senha: senha }
        });
    
        if (response.status === 200) {
          enqueueSnackbar(response.data.message || 'Operação realizada com sucesso!', { 
            variant: 'success' 
          });
          setTokenState(null)
          setId('')
          setSenha('')
        } else {
          setTokenState(null)
          setId('')
          setSenha('')
          enqueueSnackbar(response.data || 'Ocorreu um erro.', { 
            variant: 'error' 
          });
        }
      } catch (err) {
        setTokenState(null)
        setId('')
        setSenha('')
        enqueueSnackbar(err.response.data || 'Erro ao processar a solicitação!', {
          variant: 'error',
        });
      }
    }
    
  }

  return (
    <div className={`${style.wrapper} ${fadeOut ? style.fadeOut : ''}`}>
      <div className={style.overlay}></div>
      <ParticlesBackground />
      <div className={`${style.typewriter} ${reset ? style.typewriterReset : ''}`}>
        <Typewriter />
      </div>
      <Modal isOpen={vrModal} onClose={handleClose} onBack={handleBack}>
        {iframeUrl ? (
          <iframe
            src={iframeUrl}
            title="Marzipano Viewer"
            width="100%"
            height="100%"
            style={{ border: "none", borderRadius: "10px" }}
            allowFullScreen
          ></iframe>
        ) : (
          <div className={style.mapBackground}>
            <img 
              src={AlamoaMapImg} 
              className={`${style.mapItem} ${style.alamoaMap}`} 
              alt="map-img" 
              onClick={handleNull} 
            />
            <img 
              src={SabooMapImg} 
              className={`${style.mapItem} ${style.sabooMap}`} 
              alt="map-img" 
              onClick={() => handleMapClick('https://motivacao.pedradigital.com.br/saboo/')} 
            />
            <img 
              src={ValongoMapImg} 
              className={`${style.mapItem} ${style.valongoMap}`} 
              alt="map-img" 
              onClick={handleNull} 
            />
            <img 
              src={PaquetaMapImg} 
              className={`${style.mapItem} ${style.paquetaMap}`} 
              alt="map-img" 
              onClick={() => handleMapClick('https://motivacao.pedradigital.com.br/paqueta/')} 
            />
            <img 
              src={OuteirinhosMapImg} 
              className={`${style.mapItem} ${style.outeirinhosMap}`} 
              alt="map-img" 
              onClick={handleNull} 
            />
            <img 
              src={QuintaMapImg} 
              className={`${style.mapItem} ${style.quintaMap}`} 
              alt="map-img" 
              onClick={() => handleMapClick('https://motivacao.pedradigital.com.br/quinta/')} 
            />
            <img 
              src={MapImg} 
              className={style.mapBase} 
              alt="map-img" 
            />
          </div>

        )}
      </Modal>

      <div className={`${style.vrbutton} ${reset ? style.vrbuttonReset : ''}`}>
        <button className={`${style.vr} ${reset ? style.vrbuttonReset : ''}`} onClick={handleClick}>
          <FaVrCardboard style={{ marginRight: '10px', borderRadius: '6px', height: '22px', width: '30px' }} />
          <div>Visualizar Berços</div>
        </button>
      </div>

      <div className={`${style.loginBox} ${reset ? style.loginBoxReset : ''}`}>
        <div className={style.loginHeader}>
          <img src={LogoCallidus} alt="Logo Callidus" />
        </div>
        <div className={`${style.loadiv} ${loading ? style.active : ''}`}>
          <div className={`${style.loader} ${loading ? style.active : ''}`}></div>
        </div>
        <div className={`${style.sucesso} ${success ? style.show : ''}`}>
            Olá {id},<br/>
            Se o e-mail fornecido estiver correto, você deve ter recebido um e-mail com as instruções para redefinir sua senha. Verifique sua caixa de entrada e, se necessário, a pasta de spam ou lixo eletrônico.<br/>
            Se você não recebeu o e-mail, pode ter digitado o e-mail incorretamente ou talvez não haja uma conta associada a esse endereço. Se você acha que isso pode ter acontecido, por favor, tente novamente ou entre em contato com nosso suporte para assistência.<br/>
            Obrigado, Equipe Callidus<br/>
          <button type="button" onClick={handleBackToLogin} className={style.fgt_pass_success}>Voltar ao Login
          </button>
        </div>
        {tokenState && (
          <>
            <form onSubmit={handleResetPassword}>
              <div className={style.inputBox}>
                <div className={style.resetPasswd}>
                  Digite a sua nova senha:
                  <input
                          id="a"
                          className={style.inputField}
                          type="password"
                          value={id}
                          onChange={(e) => { setId(e.target.value); setError(""); }}
                          required
                        />
                  Digite a senha novamente:
                  <input
                          id="usder"
                          className={style.inputField}
                          type="password"
                          value={senha}
                          onChange={(e) => { setSenha(e.target.value); setError(""); }}
                          required
                        />
                  <button type="submit" className={style.inputSubmit}>Alterar Senha
                  </button>
                </div>
              </div>
            </form>
          </>
        )}
        {(!loading && !success && !tokenState) && (
          <form onSubmit={handleLogin}>
            <div className={style.inputBox}>
              {!reset ? (
                <>
                  <input
                    id="user"
                    className={style.inputField}
                    type="text"
                    value={id}
                    onChange={(e) => { setId(e.target.value); setError(""); }}
                    required
                  />
                  <label htmlFor="user" className={style.label}>Usuário</label>
                </>
              ) : (
                <>
                  <div className={style.fgtDiv}>
                    <h1>Esqueci minha senha</h1>
                    {!resetEmail ? (
                    <>
                    <div className={style.inputFgt}>
                      Digite seu nome de usuário:
                      <input
                        id="user-fgt"
                        className={style.inputField}
                        type="text"
                        value={id}
                        onChange={(e) => { setId(e.target.value); setError(""); }}
                        required
                      />
                    </div>
                    </> ) : (
                      <>
                        <div className={style.inputFgt}>
                          Para prosseguir, por favor, confirme seu e-mail. Abaixo está uma parte do seu e-mail registrado:
                          <p>{resetEmail}</p>
                          <input
                            id="email-fgt"
                            className={style.inputField}
                            type="text"
                            value={email}
                            onChange={(e) => { setEmail(e.target.value); setError(""); }}
                            required
                            />
                        </div>
                      </>
                    )}
                  </div>
                  <button type="button" onClick={handleBackToLogin} className={style.fgt_pass}>Voltar ao Login
                  </button>
                </>
              )}
            </div>
            <div className={style.inputBox}>
              {!reset && (
                <>
                  <input
                    id="pass"
                    className={style.inputField}
                    type={showPassword ? "text" : "password"}
                    value={senha}
                    onChange={(e) => { setSenha(e.target.value); setError(""); }}
                    required
                  />
                  <label htmlFor="pass" className={style.label}>Senha</label>
                  <span
                    className={style.eyeIcon}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                  <div className={style.forgotPassword}>
                    <button type="button" onClick={handleForgotPassword} className={style.fgt_pass}>Esqueci a senha?</button>
                  </div>
                </>
              )}
            {error && <div className={style.errorMsg}>{error}</div>}
            <button type="submit" className={style.inputSubmit}>{!reset ? 'Entrar' : 'Avançar'}
            </button>
            </div>
          </form>
        )}
        {(!reset && !success && !success && !tokenState) && (
          <>
            <div className={style.register}>
              <span>Não tem uma conta? <Link to="/register"> Registrar</Link></span>
            </div>
            <div className={style.register}>
              <span className={style.span}>Todos os direitos reservados</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
