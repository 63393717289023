import React, { useState, useEffect } from "react";
import style from "./Typewriter.module.css";

const Typewriter = () => {
  const [text, setText] = useState("");
  const [wordIndex, setWordIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const words = ["Tecnologia", "Gestão Portuária", "Agenciamento"];
  const typingSpeed = 170;
  const deletingSpeed = 100;
  const pauseTime = 1500; // Tempo de pausa antes de apagar ou mudar de palavra

  useEffect(() => {
    let timeout;

    const handleTyping = () => {
      const currentWord = words[wordIndex];
      
      if (!isDeleting) {
        // Se não estiver deletando, continue digitando a palavra
        const newText = currentWord.substring(0, text.length + 1);
        setText(newText);

        if (newText === currentWord) {
          // Palavra completa, aguardar antes de iniciar a deleção
          timeout = setTimeout(() => setIsDeleting(true), pauseTime);
        } else {
          timeout = setTimeout(handleTyping, typingSpeed);
        }
      } else {
        // Se estiver deletando, continue deletando a palavra
        const newText = currentWord.substring(0, text.length - 1);
        setText(newText);

        if (newText === "") {
          // Palavra deletada completamente, mudar para a próxima palavra
          setIsDeleting(false);
          setWordIndex((prevIndex) => (prevIndex + 1) % words.length);
          timeout = setTimeout(handleTyping, typingSpeed);
        } else {
          timeout = setTimeout(handleTyping, deletingSpeed);
        }
      }
    };

    timeout = setTimeout(handleTyping, isDeleting ? deletingSpeed : typingSpeed);

    return () => clearTimeout(timeout);
  }, [text, wordIndex, isDeleting, words]);

  return (
    <div className={style.typewriterWrapper}>
      <h2>
        Inovando em <br />
      </h2>
      <h1>
        <span className={style.typewriter}>{text}</span>
      </h1>
    </div>
  );
};

export default Typewriter;
